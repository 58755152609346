import React from 'react'
import { FieldRow, FieldWrap, Label, Helper, FieldSet, Legend, Required } from './styles'
import FieldType from './Fields'
import { FormikErrors, FormikTouched } from 'formik'
import { InputItem } from './utils'

interface Props {
	items: InputItem[]
	errors: FormikErrors<{ [field: string]: string | undefined }>
	touched: FormikTouched<{ [field: string]: string | undefined }>
	minimalist?: boolean
	color?: string
	values: { [field: string]: string }
}
const FormFieldGroup = ({ items, errors, touched, minimalist, color, values }: Props): JSX.Element => {
	return (
		<FieldRow>
			{items.map((item, index: number) => {
				const { label, inputId, inputName, width, inputRequired } = item
				if (item && item.inputType === 'checkbox') {
					return (
						<FieldWrap>
							<FieldSet errors={errors.flag} touched={touched.flag}>
								<Legend>
									{label}
									{inputRequired ? <Required> *</Required> : ''}
								</Legend>
								<FieldType {...{ item, errors, touched, values }} />
							</FieldSet>
							{touched[inputName] && errors[inputName] ? (
								<Helper>{`▲ ` + JSON.stringify(errors[inputName], null, 2)}</Helper>
							) : (
								''
							)}
						</FieldWrap>
					)
				}
				return (
					<FieldWrap width={width} key={inputId + index}>
						<Label style={color ? { color: `${color}` } : {}} htmlFor={inputId}>
							{label}
							{inputRequired ? <Required> *</Required> : ''}
							{/* <StyledError name={inputName} component="span" /> */}
						</Label>
						<FieldType
							{...{
								item,
								errors,
								touched,
								values,
								minimalist: Boolean(minimalist),
							}}
						/>
						{touched[inputName] && errors[inputName] ? (
							<Helper>{`▲ ` + JSON.stringify(errors[inputName], null, 2)}</Helper>
						) : (
							''
						)}
					</FieldWrap>
				)
			})}
		</FieldRow>
	)
}
export default FormFieldGroup
