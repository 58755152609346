import React from 'react'
import { Field } from 'formik'
import { ChevronDown } from 'react-feather'
import { Input, Select, Textarea, SelectIcon, SelectWrap, Label } from './styles'
import Checkbox from './checkbox'
import { FormikErrors, FormikTouched } from 'formik'
import { InputItem } from './utils'

interface Props {
	item: InputItem
	errors: FormikErrors<{ [field: string]: string }>
	touched: FormikTouched<{ [field: string]: string }>
	values: { [field: string]: string }
	minimalist?: boolean | string
}

export const FieldType = ({ item, errors, touched, values, minimalist }: Props): JSX.Element => {
	const {
		label,
		inputType,
		inputName,
		inputPlaceholder,
		inputId,
		inputAutocomplete,
		inputRequired,
		options,
		textareaRows,
		textareaCols,
	} = item
	switch (inputType) {
		case 'text':
		case 'email':
		case 'tel':
		case 'number':
		case 'date':
			return (
				<>
					<Input
						type={inputType}
						name={inputName}
						placeholder={!minimalist ? inputPlaceholder : label}
						id={inputId}
						autoComplete={inputAutocomplete}
						required={inputRequired ? true : false}
						valid={!errors[inputName] && Boolean(touched[inputName]) ? 1 : 0}
						invalid={Boolean(errors[inputName]) && Boolean(touched[inputName]) ? 1 : 0}
						minimalist={Boolean(minimalist)}
					/>
				</>
			)
		case 'select':
		case 'multiselect':
			return (
				<>
					<SelectWrap>
						<Select
							component={inputType}
							multiple={inputType === 'multiselect' ? true : false}
							name={inputName}
							id={inputId}
							autoComplete={inputAutocomplete}
							required={inputRequired}
							valid={!errors[inputName] && touched[inputName] ? true : false}
							invalid={errors[inputName] && touched[inputName] ? true : false}
							minimalist={Boolean(minimalist)}
						>
							<option className="placeholder" value={values && values[inputName]}>
								{values && values[inputName]}
							</option>
							{options?.map((item, index) => (
								<option key={item + index}>{item}</option>
							))}
						</Select>
						<SelectIcon>
							<ChevronDown aria-hidden />
						</SelectIcon>
					</SelectWrap>
				</>
			)
		case 'textarea':
			return (
				<>
					<Textarea
						component={inputType}
						name={inputName}
						placeholder={inputPlaceholder}
						id={inputId}
						autoComplete={inputAutocomplete}
						required={inputRequired}
						valid={!errors[inputName] && Boolean(touched[inputName]) ? 1 : 0}
						invalid={Boolean(errors[inputName]) && Boolean(touched[inputName]) ? 1 : 0}
						rows={textareaRows}
						cols={textareaCols}
						minimalist={Boolean(minimalist)}
					/>
				</>
			)
		case 'checkbox':
			if (options && options.length >= 0) {
				return (
					<>
						{options.map((value, key) => {
							return (
								<Label key={key} htmlFor={`${inputId}-${key}`} tw="text-xs">
									<Checkbox tick={values && values[inputName] && values[inputName].includes(value)} size="24px">
										<Field id={`${inputId}-${key}`} type="checkbox" name={inputName} value={value} />
									</Checkbox>
									{value}
								</Label>
							)
						})}
					</>
				)
			} else {
				return (
					<>
						<Checkbox tick={values && values[inputName] && values[inputName].includes(label)} size="24px">
							<Field
								id={inputId}
								type={inputType}
								name={inputName}
								placeholder={inputPlaceholder}
								autoComplete={inputAutocomplete}
								required={inputRequired}
								valid={!errors[inputName] && Boolean(touched[inputName]) ? 1 : 0}
								invalid={Boolean(errors[inputName]) && Boolean(touched[inputName]) ? 1 : 0}
								value={label}
								minimalist={Boolean(minimalist)}
							/>
						</Checkbox>
					</>
				)
			}
		default:
			return <></>
	}
}
export default FieldType
