import * as Yup from 'yup'
import assign from 'lodash.assign'

/** Default inputs */
const params = { subject: '' }
const defualtInputItems = [
	{
		label: 'Name',
		inputId: 'name',
		inputName: 'name',
		inputType: 'text',
		width: '1/2',
		options: null,
		inputAutocomplete: 'name',
		inputPlaceholder: 'John Smith',
		inputRequired: 'Yes',
		textareaRows: null,
		textareaCols: null,
	},
	{
		label: 'Subject',
		inputId: 'subject',
		inputName: 'subject',
		inputType: 'select',
		width: '1/2',
		options: ['demo', 'sales', 'service', 'support', 'mineral swim', 'ozone swim', 'commerical'],
		inputAutocomplete: null,
		inputPlaceholder: null,
		inputRequired: 'Yes',
		textareaRows: null,
		textareaCols: null,
	},
]

export type InputItem = {
	inputName: string
	label: string
	inputId: string
	width: string
	inputRequired: string
	inputType: string
	helpText?: string | null
	inputPlaceholder?: string | null
	inputAutocomplete?: string | null
	textareaRows?: string | null
	textareaCols?: string | null
	options?: string[] | null
}
export type InputItems = {
	title?: string
	items?: InputItem[]
	inputName: string
	label: string
	inputId: string
	width: string
	inputRequired: string
	inputType: string
	helpText?: string | null
	inputPlaceholder?: string | null
	inputAutocomplete?: string | null
	textareaRows?: string | null
	textareaCols?: string | null
	options?: string[] | null
}[]
// const nestedValidationSchema = Yup.array(Yup.string())
export type ValidationItems = {
	[inputName: string]: Yup.StringSchema | Yup.BooleanSchema | Yup.ArraySchema<Yup.StringSchema>
}[]
const flat = (arr: InputItems): InputItem[] => {
	let result: InputItem[] = []
	arr.forEach(({ items, ...rest }) => {
		result.push(rest)
		if (Array.isArray(items)) {
			result = result.concat(flat(items))
		}
	})
	const filteredResults = result.filter((item) => item.inputName) // only keep items with an inputName
	return filteredResults
}
const validationSwitch = (key: string): Yup.StringSchema | Yup.BooleanSchema | Yup.ArraySchema<Yup.StringSchema> => {
	switch (key) {
		case 'firstName':
		case 'givenName':
		case 'lastName':
		case 'familyName':
		case 'name':
		case 'fullName':
		case 'street':
		case 'streetAddress':
		case 'address1':
		case 'address2':
		case 'city':
		case 'suburb':
			return Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required(`Required`)
		case 'state':
		case 'province':
			return Yup.string().required(`Required`)
		case 'email':
		case 'emailAddress':
			return Yup.string().email('Invalid email').required(`Required`)
		case 'phone':
		case 'phoneNumber':
			return Yup.string().min(6, 'Too Short!').max(12, 'Too Long!').required(`Required`)
		case 'mobile':
		case 'mobileNumber':
			return Yup.string().min(10, 'Too Short!').max(12, 'Too Long!').required(`Required`)
		case 'postcode':
		case 'postalCode':
			return Yup.string()
				.min(4, 'Too Short!')
				.max(4, 'Too Long!')
				.matches(/^[0-9]{4}/, 'Invalid Postcode')
				.required(`Required`)
		case 'policy':
			return Yup.boolean()
				.required('The Sales & Advertising policy must be accepted.')
				.oneOf([true], 'The Sales & Advertising policy must be accepted.')
		case 'subscriberType':
			return Yup.array(Yup.string().required('Required'))
		default:
			return Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required(`Required`)
	}
}
const valuesMapped = (items: InputItem[], subject: string): { [inputName: string]: string }[] =>
	flat(items).map(({ inputName }) => ({
		[inputName]: inputName === 'subject' ? subject : '',
	}))
const validationMapped = (items: InputItem[]): ValidationItems =>
	flat(items).map(({ inputName }) => ({
		[inputName]: validationSwitch(inputName),
	}))
const validationObject = (
	items: ValidationItems
): Record<string, Yup.AnySchema<ValidationItems, ValidationItems, ValidationItems>> => assign({}, ...items)

/**
 * The forms inital values
 * @param {InputItem[]} items array of form items
 * @param {string} subject get the subject from url params
 * @returns {{ [inputName: string]: string }[]} array of inital input key value pairs
 */
export const initialValues = (
	items: InputItem[] = defualtInputItems,
	subject: string = params.subject
): { [inputName: string]: string } => assign({}, ...valuesMapped(items, subject))

/**
 * The forms validation schema
 * @param {InputItem[]} items array of form items
 * @returns {Yup.AnyObjectSchema} validation schema as Yup object
 */
export const validationSchema = (items: InputItem[]): Yup.AnyObjectSchema =>
	Yup.object().shape(validationObject(validationMapped(items)))
