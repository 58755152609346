import React from 'react'
import { FieldRow, FieldWrap, Label, Helper, FieldSet, Legend, Required } from './styles'
import FieldType from './Fields'
import { FormikErrors, FormikTouched } from 'formik'
import { InputItem } from './utils'

interface Props {
	overrideWidth?: string
	item: InputItem
	errors: FormikErrors<{ [field: string]: string | undefined }>
	touched: FormikTouched<{ [field: string]: string | undefined }>
	color?: string
	values: { [field: string]: string }
}

const FormFieldSingle = ({ overrideWidth, item, errors, touched, color, values }: Props): JSX.Element => {
	const { label, inputId, inputName, width, inputRequired } = item
	if (item && item.inputType === 'checkbox') {
		return (
			<FieldRow>
				<FieldWrap width={overrideWidth ? overrideWidth : width}>
					<FieldSet errors={errors.flag} touched={touched.flag}>
						<Legend>
							{label}
							{inputRequired ? <Required> *</Required> : ''}
						</Legend>
						<FieldType {...{ item, errors, touched, values }} />
					</FieldSet>
					{touched[inputName] && errors[inputName] ? (
						<Helper>{`▲ ` + JSON.stringify(errors[inputName], null, 2)}</Helper>
					) : (
						''
					)}
				</FieldWrap>
			</FieldRow>
		)
	}
	return (
		<FieldRow>
			<FieldWrap width={overrideWidth ? overrideWidth : width}>
				<Label style={color ? { color: `${color}` } : {}} htmlFor={inputId}>
					{label}
					{inputRequired ? <Required> *</Required> : ''}
					{/* <StyledError name={inputName} component="span" /> */}
				</Label>
				<FieldType {...{ item, errors, touched, values }} />
				{touched[inputName] && errors[inputName] ? (
					<Helper>{`▲ ` + JSON.stringify(errors[inputName], null, 2)}</Helper>
				) : (
					''
				)}
			</FieldWrap>
		</FieldRow>
	)
}
export default FormFieldSingle
